<template>
  <div class="body">
    <!-- <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell> -->
    <van-form label-width="50px" @submit="searchAdvancePaymentByInpno">
      <van-field
        v-model="inpatientName"
        center
        clearable
        required
        :rules="[{ required: true }]"
        label="患者姓名"
        placeholder="请填写住院患者姓名"
      >
      </van-field>
      <van-field
        v-model="inpatientNo"
        center
        clearable
        required
        :rules="[{ required: true }]"
        label="住院号"
        placeholder="请填写患者住院号"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            plain
            native-type="submit"
            style="margin-left: 10px"
            >查询</van-button
          >
        </template>
      </van-field>
      <van-button
        type="primary"
        size="large"
        class="btn-query2"
        color="#4AC691"
        @click="onSubmit"
        v-if="contentShow"
      >
        住院预交金充值
      </van-button>
    </van-form>
    <div class="content-box" v-if="contentShow">
      <div class="content-state content-item">
        <span v-if="payRecord.patient.inPatientStatus == 1">住院中</span>
        <span v-if="payRecord.patient.inPatientStatus == 2">预出院</span>
        <span v-if="payRecord.patient.inPatientStatus == 3">已结账</span>
        <span v-if="payRecord.patient.inPatientStatus == 4">已出院</span>
        <span v-if="payRecord.patient.inPatientStatus == 5">已清账</span>
      </div>
      <div class="content-item">
        <span class="content-item-label"
          >患者姓名：{{ payRecord.patient.name }}</span
        >
      </div>
      <div class="content-item">
        <span class="content-item-label"
          >住院号：{{ payRecord.patient.inpno }}</span
        >
      </div>
      <div class="content-item">
        <span class="content-item-label">{{
          payRecord.patient.bizDeptName
        }}</span>
        <span class="content-item-value">{{
          payRecord.patient.chargeDoctorName
        }}</span>
      </div>
      <div class="content-item">
        住院时间：{{ moment(payRecord.patient.inTime).format("YYYY-MM-DD") }}
      </div>
      <van-divider />
      <div style="line-height: 35px">
        账户余额：<span style="color: #990000"
          >￥{{ payRecord.patient.inAccRemainedFee / 100 }}</span
        >
      </div>
      <div class="content-item">
        <div class="content-item-label">
          预交总金额：<span style="color: #990000"
            >￥{{ payRecord.total / 100 }}</span
          >
        </div>
        <div class="content-item-value">
          交费次数：<span>{{ payCount }}</span
          >次
        </div>
      </div>
      <van-divider />
      <div>本次充值：</div>
      <van-form>
        <van-field
          v-model="amount"
          type="number"
          label="￥"
          placeholder="单笔最高可充值50000元"
          v-input-float="{ limit: 2, min: 0, max: 50000.0 }"
        />
      </van-form>
      <div style="font-size: 0.3rem; color: #c8c9cc">
        住院预交金不得低于0.01元
      </div>
      <div class="price-box">
        <div class="price-item" @click="setAmount(500.0)">500.00</div>
        <div class="price-item" @click="setAmount(1000.0)">1000.00</div>
        <div class="price-item" @click="setAmount(2000.0)">2000.00</div>
        <div class="price-item" @click="setAmount(5000.0)">5000.00</div>
      </div>
    </div>
    <div v-else class="history-box">
      <div v-if="historyList">历史查询记录</div>
      <van-tag
        size="large"
        style="margin: 5px"
        v-for="(v, i) in historyList"
        :key="i"
        @click="hancleClickTag(v)"
      >
        {{ v.name }}({{ v.inpatientNo }})
      </van-tag>
    </div>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item, index)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />
        添加就诊人
      </div>
    </van-popup>
    <van-dialog
      class="dialog"
      v-model="dialogShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>未查询到该住院号，请联系床管医生确认。</p>
      <van-button class="confirm-btn" @click="dialogShow = false"
        >我知道了</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "advancePayment",
  created() {
    this.getAliPayCode();
    let query = this.$route.query;
    this.inpatientNo = query.inpno || this.inpatientNo;
    this.inpatientName = query.name || this.inpatientName;
    if (this.inpatientNo && this.inpatientName) {
      this.searchAdvancePaymentByInpno();
    }
  },
  data() {
    return {
      inpnoId: "",
      showPopup: false, // 弹出层
      showPopup2: false, // 弹出层
      btnPatientLoading: false,
      chooseItem: "",
      personId: "",
      list: [],
      patList: [],
      payRecord: [],
      inpatientNo: "", // 住院号
      inpatientName: "", // 患者姓名
      payCount: "",
      amount: "",
      dialogShow: false,
      contentShow: false,
      historyList: [],
      moment: require("moment"),
    };
  },
  watch: {
    $route: function ({ query }) {
      this.inpatientNo = query.inpno || this.inpatientNo;
      this.inpatientName = query.name || this.inpatientName;
    },
  },
  methods: {
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // this.$store.commit("showLoading");
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        // 没有则进行授权
        const redirectUrl = `${domain}index.html%23%2FadvancePayment`;
        this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
          );
        } else {
          sessionStorage.setItem("AliPayCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getList();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getList();
          this.$store.commit("hideLoading");
        })
        .catch((err) => {
          this.$store.commit("hideLoading");
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    onSubmit() {
      if (!this.amount) {
        this.$notify({ message: "请填写充值金额", type: "warning" });
        return;
      }
      if (this.amount > 50000) {
        this.$notify({ message: "单笔最高金额50000元", type: "warning" });
        return;
      }
      if (this.amount < 0.01) {
        this.$notify({ message: "单笔最小金额0.01元", type: "warning" });
        return;
      }
      this.$router.push({
        path: "/advancePaymentWxPayPage",
        query: {
          price: (this.amount * 100).toFixed(0),
          inpno: this.inpatientNo,
          name: this.payRecord.patient.name,
          dept: this.payRecord.patient.bizDeptName,
        },
      });
    },
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item, index) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      sessionStorage.setItem("CurrentJzrIndex", index);
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.fetchDailyChecklist();
    },
    getList() {
      this.btnPatientLoading = true;
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.fetchDailyChecklist();
            this.isHasPatient();
            // this.fetchPatList();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    hancleClickTag(v) {
      this.inpatientName = v.name;
      this.inpatientNo = v.inpatientNo;
      this.searchAdvancePaymentByInpno();
    },
    fetchPatList() {
      let param = {
        // patid: "0010748614",
        patid: this.chooseItem.patId,
      };
      this.$http.get("/api/inhospital/pat", param).then((result) => {
        this.patList = result.data;
        this.btnPatientLoading = false;
      });
    },
    fetchDailyChecklist() {},
    searchAdvancePaymentByInpno() {
      if (
        this.$route.query.name != this.inpatientName &&
        this.$route.query.inpno != this.inpatientNo
      ) {
        this.$router.replace({
          query: {
            name: this.inpatientName,
            inpno: this.inpatientNo,
          },
        });
      }
      let param = {
        inpno: this.inpatientNo,
        name: this.inpatientName,
      };
      let historyList = localStorage.getItem("historyList2")
        ? JSON.parse(localStorage.getItem("historyList2"))
        : [];
      historyList.unshift({
        inpatientNo: this.inpatientNo,
        name: this.inpatientName,
      });
      localStorage.setItem(
        "historyList2",
        JSON.stringify(_.uniqBy(historyList, "inpatientNo"))
      );
      this.$http
        .get("/api/inhospital/eh2404", param)
        .then((result) => {
          this.payCount = result.data.result.length;
          this.payRecord = result.data;
          this.contentShow = true;
        })
        .catch((err) => {
          this.dialogShow = true;
          this.contentShow = false;
        });
    },
    setAmount(value) {
      this.amount = value;
    },
    choosePatientNo(item, index) {
      if (this.patList.length == 0) {
        this.dialogShow = true;
        return;
      }
      this.inpnoId = item.id;
      this.inpatientNo = this.patList[0].inpno;
      this.showPopup2 = false;
    },
  },
  mounted() {
    this.getList();
    this.historyList = JSON.parse(localStorage.getItem("historyList2"));
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.btn-query {
  width: 9rem;
  border-radius: 5px;
  border: none;
  font-size: 0.4rem;
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%);
}
.btn-query2 {
  width: 9rem;
  border-radius: 5px;
  border: none;
  font-size: 0.4rem;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}
/deep/ .van-form {
  margin-top: 0;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-align: center;
  font-size: 0.4rem;
}
.confirm-btn {
  width: 50%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.content-box {
  max-height: calc(100vh - 220px);
  overflow: auto;
  width: 9rem;
  margin: 10px auto;
  padding: 10px 16px;
  font-size: 0.38rem;
  text-align: left;
  background-color: #fff;
}
.content-item {
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.price-item {
  padding: 10px 5px;
  margin: 10px 0;
  background-color: #dbf9ef;
  border: solid 1px #1ad997;
  color: #1ad997;
  display: flex;
  border-radius: 4px;
  align-items: center;
}
.tips-info {
  font-size: 0.32rem;
  text-align: center;
  color: #990000;
  font-weight: 600;
  line-height: 30px;
}
.history-box {
  text-align: left;
  width: 9rem;
  margin: 10px auto;
  font-size: 0.38rem;
}
/deep/ .content-box .van-cell__title {
  text-align: center;
  width: 30px;
}
/deep/ .van-cell__title.van-field__label {
  width: 2rem !important;
  font-size: 0.38rem;
}
</style>
